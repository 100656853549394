<template>
  <div>
    <el-table
      ref="tableRef"
      class="customize-el-table"
      :data="list"
      size="small"
      style="width: 100%;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="isShowSelect"
        type="selection"
        width="55"
      />
      <el-table-column
        :key="sceneTitle"
        fixed
        prop="title"
        :label="sceneTitle"
        show-overflow-tooltip
      />
      <el-table-column
        v-if="sceneType === 'MEDICAL_PODCAST'"
        align="center"
        label="活动时间"
        width="180px"
        prop="activityTime"
      >
        <template slot-scope="{row}">
          {{ (row.activityTime || '/').replace(/(\d{4}-\d{2}-\d{2}) \d{2}:\d{2}:\d{2}/g, '$1') }}
        </template>
      </el-table-column>

      <template v-if="sceneType !== 'MEDICAL_PODCAST'">
        <el-table-column prop="createdAt" label="创建时间" align="center" />
        <el-table-column prop="createAt" align="center" :label="sceneDesc">
          <template slot-scope="scope">
            <span v-if="sceneType === 'questionnaire'">{{ scope.row.examCount }}</span>
            <span v-if="sceneType === 'academicVisit'">{{ scope.row.sceneChildTypeCount }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isCreator"
          prop="selfCanUseCount"
          align="center"
          label="组织待分配"
        >
          <template slot="header">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              content="上级组织分配的任务"
            >
              <span slot="reference">组织待分配 <i class="el-icon-question" /></span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column align="center" label="完成数/总任务数">
          <template slot="header">
            <el-popover
              placement="top-start"
              width="200"
              trigger="hover"
              :content="isCreator ? '我及我以下组织分配的任务数汇总' : '我及我以下组织分配的任务数汇总，不包含上级组织分配的任务'"
            >
              <span slot="reference">完成数/总任务数 <i class="el-icon-question" /></span>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row | inviteFilter }}</span>
          </template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column
          prop="challengeStatusDesc"
          label="状态"
          align="center"
        />
        <el-table-column
          prop="productTagName"
          label="产品"
          align="center"
        />
        <el-table-column
          label="已报名/所有客户"
          align="center"
        >
          <template slot-scope="{row}">
            {{ row.registerCount }}/{{ row.invitationCount }}
          </template>
        </el-table-column>
        <el-table-column
          label="采购/投稿作品数"
          align="center"
        >
          <template slot-scope="{row}">
            {{ row.reviewSuccessCount }}/{{ row.submitCount }}
          </template>
        </el-table-column>
        <el-table-column
          label="已完成/所有任务"
          align="center"
          sortable
          width="180"
          sort-by="allCodeCount"
        >
          <template slot-scope="{row}">
            {{ row.finishedCodeCount }}/{{ row.allCodeCount }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="academicSourceType === 'down'"
          label="创建组织"
          prop="createOrgName"
          show-overflow-tooltip
          align="center"
        />
      </template>
      <el-table-column
        align="center"
        label="操作"
        width="220"
      >
        <template slot-scope="scope">
          <div style="display: flex; gap: 8px; justify-content: center;">
            <span v-if="sceneType === 'MEDICAL_PODCAST' && scope.row.challengeStatus !== 5 && academicSourceType === 'ower'" class="text-primary-link" @click="onAddSpeaker(scope.row)">添加播客</span>
            <el-tooltip
              v-if="scope.row.challengeStatus === -1"
              effect="dark"
              :content="scope.row.failReason"
              placement="top-start"
            >
              <span class="text-primary-link">查看原因</span>
            </el-tooltip>
            <span v-else class="text-primary-link" @click="onJumpDetail(scope.row.id)">查看详情</span>
            <!-- 暂时关闭分配拜访 -->
            <span v-if="!['MEDICAL_PODCAST'].includes(sceneType)" class="text-primary-link" @click="onAllot(scope.row.id, scope.row.canEdit)">分配任务</span>
            <span v-if="sceneType === 'MEDICAL_PODCAST' && academicSourceType === 'ower'" class="text-primary-link" @click="onEdit(scope.row)">编辑</span>
            <span
              v-if="sceneType === 'MEDICAL_PODCAST' && scope.row.challengeStatus === 4 && academicSourceType === 'ower'"
              style="color: rgb(232, 49, 47);"
              class="text-primary-link"
              @click="onClose(scope.row)"
            >结束</span>
            <span
              v-if="sceneType === 'MEDICAL_PODCAST' && scope.row.challengeStatus !== 4 && academicSourceType === 'ower'"
              style="color: rgb(232, 49, 47);"
              class="text-primary-link"
              @click="onDel(scope.row.id, scope.row.canEdit)"
            >删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <AddSpeakerModal
      :selected="toBeAdded.recommendUserList"
      :disabled-list="toBeAdded.recommendUserList?.filter(item => item.disabled).map(item => item.id)"
      :visible="speakerModalVisible"
      :max="50"
      :scene-type="12"
      @onClose="onClosePop"
      @onSubmit="onSubmitGuest"
    />
  </div>
</template>
<script>
import AddSpeakerModal from '@/bizComponents/AddSpeakerModal'
export default {
  name: 'SceneChildTable',
  filters: {
    inviteFilter(v) {
      return `${v.completeCount}/${v.totalCount}`
    }
  },
  components: {
    AddSpeakerModal
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isShowSelect: {
      type: Boolean,
      default: false
    },
    sceneType: {
      type: String,
      default: 'questionnaire'
    },
    isCreator: {
      type: Boolean,
      default: true
    },
    academicSourceType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      speakerModalVisible: false,
      guestList: [],
      toBeAdded: {
        recommendUserList: []
      }
    }
  },
  computed: {
    sceneTitle() {
      switch (this.sceneType) {
        case 'questionnaire':
          return '活动名称'
        case 'academicVisit':
          return '拜访名称'
        case 'MEDICAL_PODCAST':
          return '活动名称'
        default:
          return ''
      }
      // return this.sceneType === 'questionnaire' ? '活动名称' : '拜访名称'
    },
    sceneDesc() {
      switch (this.sceneType) {
        case 'questionnaire':
          return '题目数'
        case 'academicVisit':
          return '素材数'
        default:
          return ''
      }
      // return this.sceneType === 'questionnaire' ? '题目数' : '素材数'
    }
  },
  watch: {
    isShowSelect(newVal) {
      if (!newVal) {
        this.$refs.tableRef && this.$refs.tableRef.clearSelection()
      }
    }
  },
  methods: {
    onJumpDetail(sceneId = '') {
      if (this.sceneType === 'MEDICAL_PODCAST') {
        this.$router.push({
          name: 'PodcastDetail',
          params: {
            id: sceneId
          },
          query: {
            isCreator: this.isCreator
          }
        })
      } else {
        this.$router.push(`/academic/${this.sceneType}/detail/${sceneId}?isCreator=${this.isCreator}`)
      }
    },
    onAllot(sceneId = '', canEdit) {
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${sceneId}?disabledBack=${!canEdit}&projectType=${this.questionnaireSubType}`)
    },
    handleSelectionChange(val) {
      this.$emit('onSelect', val)
    },
    async onDel(id) {
      try {
        await this.$confirm('确定删除这个活动么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const params = {
          challengeId: id
        }
        const res = await this.$axios.get(this.$API.challengeDelete, {params})
        if (res) {
          this.$emit('on-change')
          this.$message.success('操作成功')
        }
      } catch (error) {
        console.log('error: ', error)
        if (error.message) {
          this.$message.error(error.message)
        }
      }
    },
    onEdit(row) {
      if (this.sceneType === 'MEDICAL_PODCAST') {
        this.$router.push({
          name: 'CreatePodcast',
          query: {
            id: row.id
          }
        })
      } else {
        this.$router.push(`/academic/scene/${this.sceneType}/create?id=${row.id}`)
      }
    },
    async onClose(row) {
      try {
        await this.$confirm('确定结束这个活动么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const params = {
          challengeId: row.id
        }
        const res = await this.$axios.get(this.$API.challengeCllose, {params})
        if (res) {
          this.$emit('on-change')
          this.$message.success('操作成功')
        }
      } catch (error) {
        if (error.message) {
          this.$message.error(error.message)
        }
      }
    },
    onAddSpeaker(row) {
      const { recommendUserList, ...arg } = row
      this.toBeAdded = {
        ...arg,
        recommendUserList: recommendUserList?.map(item => {
          console.log('item: ', item)
          return {
            id: item.doctorId,
            disabled: item.isApplied
          }
        })
      }
      this.speakerModalVisible = true
    },
    onClosePop() {
      this.speakerModalVisible = false
      this.toBeAdded = this.$options.data().toBeAdded
    },
    onSubmitGuest(val) {
      // 判断两个数组是否相等
      const valIds = val.map(item => item.id).sort().toString()
      const recommendUserList = this.toBeAdded.recommendUserList?.map(item => item.id).sort().toString()
      if (valIds === recommendUserList || val.length === 0) {
        this.$message.error('请选择您要添加的讲者')
        return
      }

      if (val.length < 5) {
        return this.$message.error('请至少添加5位播客')
      }
      const params = {
        challengeId: this.toBeAdded.id,
        recommendUsers: val.map(item => {
          const { id } = item
          return {
            userId: id,
            userType: 1
          }
        })
      }
      this.$axios.post(this.$API.addPodcastUser, params).then(res => {
        console.log('res: ', res)
        this.$emit('on-change')
        this.$message.success('添加成功')
        this.speakerModalVisible = false
      }).catch(err => {
        this.$message.error(err.message)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.box-wrapper {
  flex-wrap: wrap;
}
.page {
  padding: 15px;
}
</style>
